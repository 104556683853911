<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                New Hire Orientation Acknowledgment Form
                <br>
                <small>New Hire Orientation & Training 2018</small>
              </b-card-title>

              <!-- Form -->
              <validation-observer ref="simpleRules">
                <b-form>

                  <b-form-input
                    v-model="form.form"
                    type="text"
                    class="hidden"
                  />

                  <b-form-input
                    v-model="form.id"
                    type="text"
                    class="hidden"
                  />

                  <p>I AGREE TO CONFORM TO ALL COMPANY PRACTICES, RULES, AND REGULATIONS RELATING TO:</p>

                  <span><b>2-HOUR ORIENTATION:</b></span>
                  <ol>
                    <li>Company Mission, Vision, & Values.  Company overview</li>
                    <li>Terms of Employment/Job description & expectations</li>
                  </ol>

                  <span><b>3-HOUR BASIC TRAINING:</b></span>
                  <ol>
                    <li>
                      <b>Basic Safety Precaution</b>
                      <ol type="a">
                        <li>Potential Hazards</li>
                        <li>Proper Lifting Techniques</li>
                        <li>Fall Prevention</li>
                      </ol>
                    </li>
                    <li>
                      <b>Emergency Procedures</b>
                      <ol type="a">
                        <li>Overview of CPR, fires, evacuation in home, natural disaster, and calling 911.</li>
                      </ol>
                    </li>
                    <li>
                      <b>Infection Control</b>
                      <ol type="a">
                        <li>Cleaning/Waste Disposal</li>
                        <li>Signs of an Infection</li>
                        <li>Proper Food Handling</li>
                      </ol>
                    </li>
                  </ol>

                  <p>I HAVE READ AND UNDERSTAND THE ABOVE ITEMS AND REALIZE THAT FAILURE TO FOLLOW THESE RULES MAY BE GROUNDS FOR DISMISSAL.</p>

                  <!-- Spacer -->
                  <hr>

                  <p>By signing this New Hire Orientation/Acknowledgment Form provided by Circle of Care, LLC, I am acknowledging that I have received my initial 2-Hour Company overview and the 3-Hour Basic Training.</p>

                  <b-row>

                    <!-- Input Employee -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Employee"
                        label-for="employee"
                      >
                        <label
                          class="sr-only"
                          for="employee"
                        >
                          * Employee
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employee"
                          vid="employee"
                          rules="required"
                        >
                          <b-form-input
                            id="employee"
                            ref="employee"
                            v-model="form.employee"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Employee"
                            title="Employee"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Date -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date"
                        vid="date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="date"
                            ref="date"
                            v-model="form.date"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Input Signature -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Employee Signature"
                        label-for="signature"
                      >
                        <label
                          class="sr-only"
                          for="signature"
                        >
                          * Employee Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employee Signature"
                          vid="employee-signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            id="employee-signature"
                            ref="signature"
                            v-model="form.signature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.signature === '' }"
                            title="Employee Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.signature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignature"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Input Date Signed -->
                  <b-row>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date Signed"
                        vid="date-signed"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date Signed"
                          label-for="date-signed"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-signed"
                          >
                            * Date Signed
                          </label>
                          <flat-pickr
                            id="date-signed"
                            ref="dateSigned"
                            v-model="form.dateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input Position -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Position"
                        label-for="position"
                      >
                        <label
                          class="sr-only"
                          for="position"
                        >
                          * Position
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Position"
                          vid="position"
                          rules="required"
                        >
                          <b-form-input
                            id="position"
                            v-model="position"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Position"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Hire Date -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Hire Date"
                        vid="hire-date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Hire Date"
                          label-for="hire-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="hire-date"
                          >
                            * Hire Date
                          </label>
                          <flat-pickr
                            id="hire-date"
                            v-model="hireDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Input Supervisor Signature -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Supervisor Signature"
                        label-for="supervisor-signature"
                      >
                        <label
                          class="sr-only"
                          for="supervisor-signature"
                        >
                          * Supervisor Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Supervisor Signature"
                          vid="supervisor-signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            id="supervisor-signature"
                            ref="supervisorSignature"
                            v-model="form.supervisorSignature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.supervisorSignature === '' }"
                            title="Supervisor Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.supervisorSignature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSupervisorSignature"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSupervisorSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Input Supervisor Date Signed -->
                  <b-row>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date Signed"
                        vid="supervisor-date-signed"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date Signed"
                          label-for="supervisor-date-signed"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="supervisor-date-signed"
                          >
                            * Date Signed
                          </label>
                          <flat-pickr
                            id="supervisor-date-signed"
                            ref="supervisorDateSigned"
                            v-model="form.supervisorDateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Supervisor Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <progress
                    v-if="uploadPercentage"
                    max="100"
                    :value.prop="uploadPercentage"
                    class="w-100 d-none"
                  />

                  <!-- Button Submit -->
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form10', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form12', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueSignaturePad } from 'vue-signature-pad'
import Swal from 'sweetalert2'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import { mapState } from 'vuex'
// import { mapGetters } from 'vuex'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,

    vSelect,
    flatPickr,
    VueSignaturePad,
    // eslint-disable-next-line
    Swal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const position = ref('')
    const hireDate = ref('')

    const signature = ref(null)
    const supervisorSignature = ref(null)

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          // console.log(jsonObjectLiterals.data)

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          // eslint-disable-next-line
          form.value.employee = jsonObjectLiterals.data.firstName + (jsonObjectLiterals.data.middleName ? ' ' + jsonObjectLiterals.data.middleName + ' ' : ' ') + jsonObjectLiterals.data.lastName

          position.value = 'HCA'
          hireDate.value = jsonObjectLiterals.data.hireDate

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm11', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                // console.log(jsonObjectLiterals.data)

                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }

                if (jsonObjectLiterals.data.signature) {
                  signature.value.fromDataURL(jsonObjectLiterals.data.signature)
                }

                if (jsonObjectLiterals.data.supervisorSignature) {
                  supervisorSignature.value.fromDataURL(jsonObjectLiterals.data.supervisorSignature)
                }

                if (form.value.id) {
                  form.value.form = 'folder3_form11'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      // wrap: true, // set wrap to true only when using 'input-group'
      // altFormat: 'm/d/Y',
      // altInput: true,
      dateFormat: 'Y-m-d',
    }

    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const vueSupervisorSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      position,
      hireDate,

      signature,
      supervisorSignature,

      formUpdate,
      formUpdateStatus,

      formCopy,

      formsOptions,

      userData,

      flatPickrConfig,
      vueSignaturePadOptions,
      vueSupervisorSignaturePadOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,
    }
  },
  computed: {
    ...mapState('app-hr', ['uploadPercentage']),
    // ...mapGetters('app-hr', { uploadPercentage: 'getUploadPercentage' }),
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          if (jsonObjectLiterals.data.signature) {
            self.$refs.signature.clearSignature()
            self.$refs.signature.fromDataURL(jsonObjectLiterals.data.signature)
          }

          if (jsonObjectLiterals.data.supervisorSignature) {
            self.$refs.supervisorSignature.clearSignature()
            self.$refs.supervisorSignature.fromDataURL(jsonObjectLiterals.data.supervisorSignature)
          }

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      /* let object = { isEmpty: null, data: null }

      object = this.$refs.signature.saveSignature()
      this.form.signature = !object.isEmpty ? object.data : ''

      object = this.$refs.supervisorSignature.saveSignature()
      this.form.supervisorSignature = !object.isEmpty ? object.data : '' */

      this.$refs.simpleRules.validate().then(success => {
        /* this.isNotSaved = true

        store
          .dispatch(this.action === 'add' ? 'app-hr/addForm11' : 'app-hr/updateForm11', this.form)
          .then(response => {
            // eslint-disable-next-line
            const jsonObjectLiterals = response.data.response.data

            this.form.id = jsonObjectLiterals.id

            // console.log(jsonObjectLiterals)

            Swal.fire({
              icon: 'success',
              title: 'Saved!',
              text: 'Saved successfully.',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(error => {
            console.error(error)

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .finally(() => {
            self.isNotSaved = false
          }) */
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm11' : 'app-hr/updateForm11', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  // console.log(jsonObjectLiterals)

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })

          // eslint-disable-next-line
          // alert('form submitted!')
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    clearSignature() {
      this.$refs.signature.clearSignature()

      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''

      if (this.form.signature === '') {
        this.$refs.simpleRules.refs['employee-signature'].reset()
      }
    },
    undoSignature() {
      this.$refs.signature.undoSignature()

      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''

      if (this.form.signature === '') {
        this.$refs.simpleRules.refs['employee-signature'].reset()
      }
    },
    clearSupervisorSignature() {
      this.$refs.supervisorSignature.clearSignature()

      const { isEmpty, data } = this.$refs.supervisorSignature.saveSignature()
      this.form.supervisorSignature = !isEmpty ? data : ''

      if (this.form.supervisorSignature === '') {
        this.$refs.simpleRules.refs['supervisor-signature'].reset()
      }
    },
    undoSupervisorSignature() {
      this.$refs.supervisorSignature.undoSignature()

      const { isEmpty, data } = this.$refs.supervisorSignature.saveSignature()
      this.form.supervisorSignature = !isEmpty ? data : ''

      if (this.form.supervisorSignature === '') {
        this.$refs.simpleRules.refs['supervisor-signature'].reset()
      }
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      let object = { isEmpty: null, data: null }

      object = this.$refs.signature.saveSignature()
      this.form.signature = !object.isEmpty ? object.data : ''

      object = this.$refs.supervisorSignature.saveSignature()
      this.form.supervisorSignature = !object.isEmpty ? object.data : ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
